.dt-toolbar {
	display: block;
	position: relative;
	padding: 6px 7px 1px;
	width: 100%;
	float: left;
	border-bottom: 1px solid #ccc;
	background: #fafafa;
}

.dt-toolbar-footer {
	background: #fafafa;
	font-size: 11px;
	overflow: hidden;
	padding: 5px 10px;
	border-top: 1px solid #ccc;
	-webkit-box-shadow: inset 0 1px #fff;
	-moz-box-shadow: inset 0 1px #fff;
	-ms-box-shadow: inset 0 1px #fff;
	box-shadow: inset 0 1px #fff;
}

.dt-toolbar >:first-child,
.dt-toolbar-footer >:first-child {
	padding-left:0px !important;
}

.dt-toolbar >:last-child,
.dt-toolbar-footer >:last-child {
	padding-right:0px !important;
}

//table.dataTable thead .sorting { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAgMAAAAPPt5fAAAACVBMVEUAAADIyMjl5eVIBBP/AAAAAXRSTlMAQObYZgAAAClJREFUCNdjwAYEwGQKiGDsBJFsTA5AUoJhAqZaiDhEDVg9RC/MHEwAANsMA91AQfd/AAAAAElFTkSuQmCC) no-repeat center right; }
//table.dataTable thead .sorting_asc { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAQMAAABInqSPAAAABlBMVEUAAABVVVUT3Vn+AAAAAXRSTlMAQObYZgAAAB1JREFUCNdjwAkcgPgBAwPjBwYG5h8MDOx/GAgBAKRJBBCQbHkwAAAAAElFTkSuQmCC) no-repeat center right; }
//table.dataTable thead .sorting_desc { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAQMAAABInqSPAAAABlBMVEUAAABVVVUT3Vn+AAAAAXRSTlMAQObYZgAAABxJREFUCNdjIATY/zAwMP9gYGD8AOQ8AGIHnEoBkNkEEEbbutQAAAAASUVORK5CYII=) no-repeat center right; }
//
//table.dataTable thead .sorting_asc_disabled { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAQMAAABInqSPAAAABlBMVEUAAABUVFR8AzIeAAAAAnRSTlMAf7YpoZUAAAAdSURBVAjXY8AJHID4AQMD4wcGBuYfDAzsfxgIAQCkSQQQkGx5MAAAAABJRU5ErkJggg==) no-repeat center right; }
//table.dataTable thead .sorting_desc_disabled { background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATAQMAAABInqSPAAAABlBMVEUAAABUVFR8AzIeAAAAAnRSTlMAf7YpoZUAAAAcSURBVAjXYyAE2P8wMDD/YGBg/ADkPABiB5xKAZDZBBBG27rUAAAAAElFTkSuQmCC) no-repeat center right; }


table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
	background-color: rgb(238, 238, 238);
}

.dataTables_empty {
	padding: 20px 10px !important;
	font-size: 14px;
	text-align: center;
	color: #575757;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
	padding: 8px 10px;
}

table.dataTable thead > tr > th,
table.dataTable tfoot > tr > th {
	padding-left:9px !important;
}
table.dataTable thead > tr > th > input,
table.dataTable tfoot > tr > th > input {
	width:100% !important;
	font-weight:normal;
}

table.dataTable thead > tr > th.hasinput {
	padding: 6px !important;
}

div.dataTables_info {
	padding-top: 9px;
	font-size: 13px;
	font-weight: bold;
	font-style: italic;
	color: #969696;
}

/*
 * Double lines in thead tfoot
 */

.dataTable thead>tr>th {
	border-left:none !important;
}

/* checkbox */
.dataTable input[type=checkbox].checkbox,
.dataTable input[type=radio].radiobox {
	left: -999px;
}

table.dataTable thead>tr>th.sorting_disabled {
	padding-right: 9px !important;
}

/*
 * DT FIlter
 */
.dataTables_filter {
	float:left;
	width:100%;
}

.dataTables_filter .input-group-addon {
	width: 32px;
	margin-top: 0px;
	float: left;
	height: 32px;
	padding-top: 8px;
}
.dataTables_filter .input-group-addon + .form-control {
	float:left;
}


/*
 * LTR filter position
 */


.dt-toolbar >:first-child .dataTables_filter >:only-child,
.dt-toolbar >:first-child .DTTT,
.dt-toolbar >:first-child .pagination,
.dt-toolbar >:first-child .dataTables_length  {
	float:left !important;
}

.dt-toolbar >:last-child .dataTables_filter >:only-child,
.dt-toolbar >:last-child .DTTT,
.dt-toolbar >:last-child .pagination,
.dt-toolbar >:last-child .dataTables_length  {
	float:right;
}

/* bottom portion */

.dt-toolbar-footer >:first-child .dataTables_filter >:only-child,
.dt-toolbar-footer >:first-child .DTTT,
.dt-toolbar-footer >:first-child .pagination,
.dt-toolbar-footer >:first-child .dataTables_length  {
	float:left;
}

.dt-toolbar-footer >:last-child .dataTables_filter >:only-child,
.dt-toolbar-footer >:last-child .DTTT,
.dt-toolbar-footer >:last-child .pagination,
.dt-toolbar-footer >:last-child .dataTables_length  {
	float:right;
}

/*
 * PRINT
 */

body.DTTT_Print {
	background:#fff !important;
}

.DTTT_Print #main {
	margin:0px !important;
}

.DTTT_PrintMessage {
	font-size: 20px;
	display: block;
	width: 100%;
	text-align: center;
	padding-top: 10px;
	font-weight: bold;
}

.DTTT_Print table.dataTable {
	margin-top:0px !important;
}

.DTTT_Print .dataTables_wrapper table {border: 1px solid #C7C7C7 !important;}

/*
 * COLUMN SHOW/HIDE
 */

div.ColVis {
	margin-bottom:0px !important;
}

ul.ColVis_collection {
	width: 127px;
}
ul.ColVis_collection li span {
	vertical-align: 3px;
}

ul.ColVis_collection li label {
	margin-bottom:0px !important;
}

/*
 * Processing Fix
 */

.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, to right to bottom, to left to bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -moz-linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
}

/* custom font icon */
.dataTable tbody .details-control{
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.dataTable tbody .details-control:before{
  font-family: 'FontAwesome';
  content: "\f055";
  font-size: 1.4em;
  color:@brand-success;
}
.dataTable tbody .shown .details-control:before{
  content: "\f056";
   color:@brand-danger;
}

.dataTable tbody .shown + tr > td {
	padding:0px !important;
}

.dataTable tbody .shown {
	background: rgba(205, 209, 98, 0.2) !important;
}

.dataTable tbody .shown > td {
	background:none !important;
}

.dataTable tbody .shown + tr > td > table > tbody > tr:last-child{
	border-bottom: 1px solid rgb(194, 194, 194);
}

.dataTable tbody .shown + tr > td > table tbody tr {
	background-color: rgba(205, 209, 98, 0.05) !important;
}

.dataTable tbody .shown .table>tbody>tr>td {
	border:none !important;
}

.table-bordered .progress {
	background:#fff !important;
	-webkit-box-shadow: 0 1px 0 transparent,0 0 0 1px #D6D6D6 inset;
	-moz-box-shadow: 0 1px 0 transparent,0 0 0 1px #D6D6D6 inset;
	box-shadow: 0 1px 0 transparent,0 0 0 1px #D6D6D6 inset;
}

/* datatable scroll-y bug fix for firefox */

.dataTables_scroll .dataTables_scrollHead,
.dataTables_scroll .dataTables_scrollBody,
.dataTables_scroll + .dt-toolbar-footer {
	float:left;
}
.dataTables_scroll + .dt-toolbar-footer {
	width:100%;
}

.dataTables_scroll .dataTables_scrollHeadInner {
	margin-top:-6px;
}

.dataTables_scroll .dataTables_scrollHead {
	background: rgb(244, 244, 244);
}
