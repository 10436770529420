/*
 * TODO's
 */

.todo-group-title {
	margin:0;
	line-height:31px;
	padding: 0 0 0 10px;
	background:lighten( @gray-lightest, 2.5%);
	border-bottom:1px solid darken(@gray-lighter, 3%);
	border-top:1px solid @gray-lightest;
	color:@gray-light;
}

.todo {
	margin:0;
	padding:0;
	min-height:5px;
	list-style:none;
}

.todo > li {
	display:block;
	position:relative;
	overflow:hidden;
	border-bottom:1px solid darken(@gray-lighter, 3%);
	margin:0 5px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAQCAYAAADagWXwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0RkQ1OEY4NTM4NUIxMUUzQjdCMUMxQzJCQUE3MTMxOCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0RkQ1OEY4NjM4NUIxMUUzQjdCMUMxQzJCQUE3MTMxOCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRGRDU4RjgzMzg1QjExRTNCN0IxQzFDMkJBQTcxMzE4IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjRGRDU4Rjg0Mzg1QjExRTNCN0IxQzFDMkJBQTcxMzE4Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+pTD+wgAAACtJREFUeNpimDBhwn8gYABhdDYjiIELMDHgAUwTJ06Ea0VnjxpLO2MBAgwAGYZLegQbQ3UAAAAASUVORK5CYII=) 1px 11px no-repeat;

}

.todo > li:last-child, .todo > li:only-child {
	border-bottom:none;
}

.todo > li >:first-child {
	display: block;
	border-right: 1px solid #FFE1EB;
	height: 100%;
	padding: 6px 11px 6px 18px;
	width: 20px;
	vertical-align: top;
	position: absolute;
}.todo > li >:first-child:hover {
	cursor: move;
}.todo > li.complete >:first-child:hover {
	cursor:default;
}.todo > li.complete {
	background:none;
}

.todo > li > p {
	height: 100%;
	margin-left: 52px;
	border-left: 1px solid #FFE1EB;
	display: inline-block;
	padding: 8px 0px 6px 7px;
	margin-bottom: 0;
	min-height: 37px;
	line-height: normal;
	font-size:14px;
	font-weight: 500;
	color: @gray-dark;
}

.todo > li > p > span {
	display:block;
	line-height:12px;
	font-size:10px;
	font-weight: normal;
}
.todo > li > p >:first-child {
	margin-top:-5px;
	color:@gray-light;
	margin-bottom:4px;
}
.todo > li > p > .date {
	color:@gray-mid-light;
}

.todo > li.complete >* {
	text-decoration:line-through;
	font-style:italic
}

/* drag */
.todo > li.ui-sortable-helper {
	border-top:1px solid @gray-lighter;
	background:rgba(113, 132, 63,0.1);
}
