/*
 * SEARCH RESULTS
 */

.search-results {
	padding: 18px 5px;
}

.search-results + .search-results {
	border-top: 1px dashed #E3E3E3;
}
.search-results>:first-child {
	margin-bottom:4px;
	font-weight:400;
}

.search-results>:first-child a {
	text-decoration:underline;
}
.search-results .url {
	font-style: normal;
	font-size:14px;
}
.search-results img {
	display: inline-block;
	margin-top:4px;
	margin-right: 4px;
	width: 80px;
}
.search-results > div {
	display:inline-block;
	vertical-align:top;
}
.search-results .note {
	margin:0;
	line-height:normal; 	
}
.search-results .note a {
	text-decoration:none !important;
	color:@gray-dark;
}.search-results .note a:hover {
	color:#ed1c24;
}
