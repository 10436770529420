/* Ion.RangeSlider
// css version 2.0.3
// © 2013-2014 Denis Ineshin | IonDen.com
// ===================================================================================================================*/

/* =====================================================================================================================
// RangeSlider */

.irs {
    position: relative; display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
    .irs-line {
        position: relative; display: block;
        overflow: hidden;
        outline: none !important;
    }
        .irs-line-left, .irs-line-mid, .irs-line-right {
            position: absolute; display: block;
            top: 0;
        }
        .irs-line-left {
            left: 0; width: 11%;
        }
        .irs-line-mid {
            left: 9%; width: 82%;
        }
        .irs-line-right {
            right: 0; width: 11%;
        }

    .irs-bar {
        position: absolute; display: block;
        left: 0; width: 0;
    }
        .irs-bar-edge {
            position: absolute; display: block;
            top: 0; left: 0;
        }

    .irs-shadow {
        position: absolute; display: none;
        left: 0; width: 0;
    }

    .irs-slider {
        position: absolute; display: block;
        cursor: default;
        z-index: 1;
    }
        .irs-slider.single {

        }
        .irs-slider.from {

        }
        .irs-slider.to {

        }
        .irs-slider.type_last {
            z-index: 2;
        }

    .irs-min {
        position: absolute; display: block;
        left: 0;
        cursor: default;
    }
    .irs-max {
        position: absolute; display: block;
        right: 0;
        cursor: default;
    }

    .irs-from, .irs-to, .irs-single {
        position: absolute; display: block;
        top: 0; left: 0;
        cursor: default;
        white-space: nowrap;
    }

.irs-grid {
    position: absolute; display: none;
    bottom: 0; left: 0;
    width: 100%; height: 20px;
}
.irs-with-grid .irs-grid {
    display: block;
}
    .irs-grid-pol {
        position: absolute;
        top: 0; left: 0;
        width: 1px; height: 8px;
        background: #000;
    }
    .irs-grid-pol.small {
        height: 4px;
    }
    .irs-grid-text {
        position: absolute;
        bottom: 0; left: 0;
        white-space: nowrap;
        text-align: center;
        font-size: 9px; line-height: 9px;
        padding: 0 3px;
        color: #000;
    }

.irs-disable-mask {
    position: absolute; display: block;
    top: 0; left: -1%;
    width: 102%; height: 100%;
    cursor: default;
    background: rgba(0,0,0,0.0);
    z-index: 2;
}
.irs-disabled {
    opacity: 0.4;
}
.lt-ie9 .irs-disabled {
    filter: alpha(opacity=40);
}


.irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important;
}
.irs-slider.single:before {
	position: absolute;
	display: block;
	content: "";
	top: -50%;
	left: -150%;
	width: 400%;
	height: 200%;
	background: rgba(red(@black), green(@black), blue(@black),0.0);
}
.irs-slider.from {
	left: 100px;
}
.irs-slider.from:before {
	position: absolute;
	display: block;
	content: "";
	top: -50%;
	left: -300%;
	width: 400%;
	height: 200%;
	background: rgba(red(@black), green(@black), blue(@black),0.0);
}
.irs-slider.to {
	left: 300px;
}
.irs-slider.to:before {
	position: absolute;
	display: block;
	content: "";
	top: -50%;
	left: 0;
	width: 400%;
	height: 200%;
	background: rgba(red(@black), green(@black), blue(@black),0.0);
}

/*
 * CUST
 *
 */

.irs {
	height: 40px;
}
.irs-with-grid {
	height: 60px;
}
.irs-line {
	height: 8px;
	top: 25px;
	background: @gray-lighter;
}
.irs-line-left {
	height: 8px;
}
.irs-line-mid {
	height: 8px;
}
.irs-line-right {
	height: 8px;
}

.irs-bar {
	height: 8px;
	top: 25px;
}

.irs-slider {
	width: 10px;
	height: 22px;
	top: 17px;
	border: 1px solid @white;
	background: @handle-bg-color;
}
.irs-slider:hover {
	background: darken(@handle-bg-color, 10%);
}
#irs-active-slider {
	background: #686868;
	box-shadow: rgba(red(@black), green(@black), blue(@black), 0.3) 1px 1px 1px 0px;
	width: 12px;
	height: 24px;
	top: 16px;
}

.irs-min, .irs-max {
	color: @gray-light;
	font-size: 12px;
	line-height: 1.333;
	text-shadow: none;
	top: 0;
	padding: 1px 3px;
	background: rgba(red(@black), green(@black), blue(@black),0.1);
	border-radius: 0;
}
.lt-ie9 .irs-min, .lt-ie9 .irs-max {
	background: @gray-mid-light;
}

.irs-from, .irs-to, .irs-single {
	color: @white;
	font-size: 10px;
	line-height: 1.333;
	text-shadow: none;
	padding: 1px 5px;
	background: rgba(red(@black), green(@black), blue(@black),0.4);
	border-radius: 0;
}
.lt-ie9 .irs-from, .lt-ie9 .irs-to, .lt-ie9 .irs-single {
	background: @gray-light;
}

.irs-grid-pol {
	background: #99a4ac;
}
.irs-grid-text {
	color: #99a4ac;
}
.irs-bar {
	background: @slider-bar-color;
	top: 25px;
	height: 8px;
	margin-left: -5px;
}
