	#chat-container *, #chat-container *:after, #chat-container *:before {
		box-sizing:content-box;
		-moz-box-sizing:content-box;
		-webkit-box-sizing:content-box;
	}

	#chat-container input[type='text'] {
		box-sizing:border-box;
		-moz-box-sizing:border-box;
		-webkit-box-sizing:border-box;
	}

	#chat-container {
		position: absolute;
		background: #fff;
		width: 250px;
		height: 270px;
		z-index: 2;
		border-left: 1px solid #CDCECF;
		right: -251px;
		top: 0px;
		background: #fff;
		transition-duration: .25s;
		-webkit-transition-duration:.25s;
		-moz-transition-duration:.25s;
		-o-transition-duration:.25s;
		-ms-transition-duration:.25s;

	}

	#chat-container:hover, #chat-container:hover .chat-list-open-close {
		border-color: #A7A7A7;
	}

	#chat-container.open {
		right: 0px;
		top: 0px;
	}

	#chat-container .chat-list-open-close {
		display: block;
		width: 25px;
		height: 25px;
		border: 1px solid #CDCECF;
		border-right: 1px solid #f2f4f8 !important;
		position: absolute;
		left: -32px;
		top: 7%;
		border-radius: 50% 0 0 50%;
		background: #FFF;
		padding: 3px 1px 3px 5px;
		font-size: 21px;
		line-height: 22px;
		cursor: pointer;
		color: #868686;
		box-shadow: inset 0 0.2em 0 rgba(0, 0, 0, 0.05);
		-moz-box-shadow: inset 0 0.2em 0 rgba(0, 0, 0, 0.05);
		background:#f2f4f8;
		text-align:center;
	}

	#chat-container .chat-list-open-close b {
		position: absolute;
		right: 0px;
		background: #ed1c24;
		line-height: 9px;
		height: 10px;
		width: 10px;
		font-size: 0;
		top: 3px;
		border: 1px solid #FFF;
		border-radius: 3px;
		font-size: 9px;
		text-align: center;
		vertical-align: middle;
		color: #fff;
		font-weight: normal;
	}

	#chat-container.open .chat-list-open-close i:before {
		content: "\f00d" !important;
	}

	#chat-container.open .chat-list-open-close b {
		display:none;
	}

	#chat-container .chat-list-body {
		height: 217px;
		overflow-y: scroll;
		overflow-x:hidden;
		display:block;
		padding:0;
		box-sizing:border-box;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		background:#f2f4f8;
	}

	#chat-container #chat-users {
		list-style:none;
		margin:10px 0;
		padding:0;
	}
	#chat-container #chat-users li {
		margin-bottom:3px;
		margin-left:5px;
	}

	#chat-container #chat-users li a {
		display:block;
		font-size:13px;
		color:#3d3d3d;
		text-decoration:none !important;
		box-sizing:border-box;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		padding:3px 8px 3px 3px;
		line-height:33px;
		vertical-align:middle;
	}
	#chat-container #chat-users li a:hover {
		background:#e0e4ee;
	}

	#chat-container #chat-users li a img {
		width:33px;
		height:auto;
		margin-right:10px;
	}
	#chat-container #chat-users li a i {
		color:#ACACAC;
		font-size:8px;
		line-height:34px;
		font-style:normal;
	}
	#chat-container #chat-users li a i.last-online {
		font-size:12px;
		letter-spacing:-1px;
	}

	#chat-container .chat-list-footer {
		overflow: hidden;
		border-top:1px solid #CCC;
		padding:10px;
		box-sizing:border-box;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
	}

	#chat-container .chat-list-footer input[type="text"] {
		border: 1px solid #bababa !important;
	}

	#chat-container .control-group {
		padding:0px;
	}

	.chat-body {
		background: #FAFAFA;
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(to bottom, #FAFAFA 0%, #FFF 100%);
		background: -webkit-gradient(linear, to right to bottom, to right to top, color-stop(0%,#FAFAFA), color-stop(100%,#FFF));
		background: -webkit-linear-gradient(to bottom, #FAFAFA 0%,#FFF 100%);
		background: -o-linear-gradient(to bottom, #FAFAFA 0%,#FFF 100%);
		background: -ms-linear-gradient(to bottom, #FAFAFA 0%,#FFF 100%);
		background: linear-gradient(to bottom, #F5FCFF 0%,#FFF 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#ffffff',GradientType=0 );
		box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.04);
		-moz-box-shadow: inset -2px -2px 5px rgba(0, 0, 0, 0.04);
		display: block;
		height: 270px;
		overflow-y: scroll;
		overflow-x:hidden;
		padding:10px;
		box-sizing:border-box;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		border:1px solid #fff;
		border-top:none;
	}

	.chat-body ul {
		margin:0;
		padding:0;
	}

	.chat-body li.message {
		display:block;
		position:relative;
		margin:20px 20px 0;
	}
	.chat-body li:first-child {
		margin-top:10px;
	}
	.chat-body li.message img {
		display:inline-block;
		border-left:4px solid #fff;
		position:absolute;
	}
	.chat-body li.message img.online {
		border-left-color:#00a300;
	}
	.chat-body li.message img.offline {
		border-left-color:#ddd;
	}
	.chat-body li.message img.busy {
		border-left-color:#A90329;
	}
	.chat-body li.message img.away {
		border-left-color:#ffc40d;
	}
	.chat-body li.message .message-text {
		display:inline-block;
		vertical-align:top;
		box-sizing:border-box;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		padding:0;
		margin-left: 65px;
		line-height: normal;
	}
		.chat-body li.message .message-text time {
			position:absolute;
			top:1px;
			right:0px;
			font-size:10px;
			line-height:10px;
			color:#AFAFAF;
		}
		.chat-body li.message .message-text .chat-file:nth-child(3){
			margin-top:15px;
		}
		.chat-body li.message .message-text .chat-file {
			display:block;
			margin:3px 0;
			padding:4px;
			border:1px dotted #ddd;
			background:rgba(0,0,0,.05);
			border-radius:4px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			text-align:right;
			box-sizing:border-box;
			-webkit-box-sizing:border-box;
			-moz-box-sizing:border-box;
		}
			.chat-body li.message .message-text .chat-file.row-fluid [class*="span"] {
				min-height:10px !important;
			}
			.chat-body li.message .message-text .chat-file b {
				font-style:italic;
				white-space: nowrap;
				width: 250px;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align:left;
			}

		.chat-body li.message .message-text a.username {
			display:block;
			font-weight:bold;
			margin-bottom:4px;
			vertical-align:top;
			line-height:14px;
			font-size:14px;
			letter-spacing:-1px;
			text-decoration:none !important;
		}

		/* message reply */

		.profile-message {
			background:none !important;
			height:auto !important;
			box-shadow:none !important;
			-moz-box-shadow:none !important;
			-webkit-box-shadow:none !important;
			overflow:hidden;
		}

		.profile-message li.message.message-reply {
			margin-left: 85px !important;
			background: #F7F7F7;
			padding: 10px;
			margin: 3px 0 0 20px;
		}.profile-message li.message.message-reply img {
			width: 35px !important;
		}.profile-message li.message.message-reply .message-text {
			margin-left:45px !important;
		}

		.profile-message .wall-comment-reply {
			margin-left:85px;
			margin-bottom:20px;
		}



	.chat-footer {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		background: rgba(248, 248, 248, 0.9);
		padding: 0 10px 15px;
		position: relative;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}
	.textarea-div {
		background-color: #FFF;
		border: 1px solid #CCC;
		border-bottom: none;
		margin: 10px 0 0;
	}
	.typearea {
		cursor: text;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 90px;
		padding: 5px 25px 5px 5px;
		position: relative;
		border-bottom: 1px solid #EEE;
	}
	.typearea textarea {
		width: 100%;
		border-color: transparent !important;
		box-sizing: border-box;
		box-shadow: none;
		-moz-box-shadow: none ;
		-moz-box-sizing :border-box;
		-webkit-box-sizing: border-box;
		-webkit-box-shadow: none;
		overflow: hidden;
		resize: none;
		padding: 0;
		margin: 0;
		-webkit-transition: height 0.2s;
		-moz-transition: height 0.2s;
		transition: height 0.2s;
		outline: none;
		resize: none;
		min-height:61px;
	}

	textarea.clone {
		position: absolute;
		visibility: hidden;
	}

	.textarea-controls {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		padding: 5px;
		display: block;
		background-color: #FFF;
		border: 1px solid #CCC;
		border-top: none;
		height: 43px;
		line-height:33px;
		overflow: hidden;
	}

	.textarea-controls > label {
		margin:3px 10px 0 10px;
	}

	.textarea-controls .smart-form .checkbox {
		padding-left: 22px !important;
	}

	@media (min-width: 768px) and (max-width: 880px) {
		.chat-body li.message .message-text .chat-file b {
			width: 150px;
		}
	}

	@media only screen and (min-width: 320px) and (max-width: 479px) {
		.chat-body li.message .message-text .chat-file b {
			width: 150px;
		}
	}

	@media (max-width: 480px) {
		.chat-body li.message .message-text .chat-file b {
			width: 150px;
		}
	}



