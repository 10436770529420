@asideWidth: 270px;
@orange: #F18523;
@headerColor: #172943;
@asideColor-start: #E6EBF3; /* left side */
@asideColor-end: #E6EBF3; /* right side */
@smart-navbar-height: 60px;
@base-url: "/bundles/layout/images";
@html-background-image: "mybg.png";
@body-background-image: "mybg.png";
@menuItemsColor: #717377;
@menuLevel2Color: #717377;

body {
  background-color: #FFFFFF;
  min-height: calc(100vh - 60px);
}
#extr-page #header {
  background-color: @headerColor;
}
#extr-page #header #logo {
  margin-top: 14px;
}

  // need to override just one or two small stylistic things and don't want to edit the main .less files?  Add those overrides here
.smart-form .input-file .button {
  top: 5px;
}

.smart-form {
  .required {
    &:after {
      content: ' *'
    }
  }
}

.login-info > span {
  padding: 0;
  height: 28px;
  background: @asideColor-start;
  color: @headerColor;
}

.login-info span {
  text-transform: capitalize;
  //noinspection LessResolvedByNameOnly
  font-size: @font-size-base  + 1px; /* 14px~ */
  display: block;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 100%;
  margin-top: 10px;
  margin-left: -10px;
  text-align: center;
  border-bottom-color: @asideColor-start;
}

aside {
  color: @menuItemsColor;
  display: block;
  background: @asideColor-end;
  background: url(@asideDataURL); /* à changer si on change de couleur */
  background: linear-gradient(to right, @asideColor-end 93%, @asideColor-start 100%);
  min-height: 100%;

  ul {

  }
}

#header {
  background: @headerColor;
  height: @smart-navbar-height;
}

nav ul li.open > a.currentParent {
  color: @headerColor;
  font-weight: bold;
}
nav ul li {
  &.active {
    > a.currentItem {
      color: @orange !important;
    }
  }
}

nav ul a {
  color: @menuItemsColor;
}

nav ul li a {
  color: @menuItemsColor;
}

nav ul ul {
  background: none;

  li {
    background: none;
  }
}

.fixed-header #main {
  margin-top: @smart-navbar-height;
}

.btn-primary {
  background: @orange;
  border-color: #FF7E00;
}

.cell-action a {
  margin-right: 0.5em;
}

.paginate_button.active a {
  background: @orange;
  border-color: #FF7E00;
}

.language_menu {
  color: white;
}

#extr-page, #logo {
  //margin-top: 8px;
}

/* inapp jarvis theme */
.jarviswidget-color-inapp > header {
  border-color: darken(@asideColor-start, 3%) !important;
  background: @asideColor-start;
  color: @white;
}

.jarviswidget-color--inapp & > header & > .jarviswidget-ctrls a, .jarviswidget-color--inapp .nav-tabs li:not(.active) a {
  color: @white !important;
}

.jarviswidget-color--inapp {
  .nav-tabs {
    li {
      a:hover {
        color: #333 !important;
      }
    }
  }
}


#csv_preview_table {
  td {
    section {
      margin-bottom: 0 !important;
    }
  }
}

.color-success {
  color: @brand-success;
}

.color-danger {
  color: @brand-danger;
}
.page-footer {
  background-color: @headerColor;
  color: #FFFFFF;
}
